import { FontAwesome5 } from "@expo/vector-icons";
import {
	//Raleway_100Thin,
	//Raleway_200ExtraLight,
	//Raleway_300Light,
	//Raleway_400Regular,
	Raleway_500Medium,
	Raleway_600SemiBold,
	Raleway_700Bold,
	//Raleway_800ExtraBold,
	//Raleway_900Black,
	//Raleway_100Thin_Italic,
	//Raleway_200ExtraLight_Italic,
	//Raleway_300Light_Italic,
	//Raleway_400Regular_Italic,
	Raleway_500Medium_Italic,
	Raleway_600SemiBold_Italic,
	Raleway_700Bold_Italic,
	//Raleway_800ExtraBold_Italic,
	//Raleway_900Black_Italic,
} from "@expo-google-fonts/raleway";
import * as Sentry from "@sentry/react-native";
import Constants from "expo-constants";
import * as Font from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import { StatusBar } from "expo-status-bar";
import React from "react";
import { StyleSheet, View } from "react-native";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { HeaderButtonsProvider } from "react-navigation-header-buttons/HeaderButtonsProvider";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { MedicationProvider } from "./components/MedicationContext";
import NativeBottomSheetModalProvider from "./components/NativeBottomSheetModalProvider";
import { NewsProvider } from "./components/NewsContext";
import { RootSafeAreaProvider } from "./components/RootSafeAreaContext";
import { SmpcProvider } from "./components/SmpcContext";
import RootNavigationContainer from "./navigation/RootNavigationContainer";
import RootNavigator from "./navigation/RootNavigator";
import store, { persistor } from "./store";

Sentry.init({
	dsn: Constants.expoConfig.extra.sentry.dsn,
	enableAutoSessionTracking: true,
});

function App() {
	const [isLoading, setLoading] = React.useState(true);

	React.useEffect(() => {
		async function loadAssets() {
			await SplashScreen.preventAutoHideAsync();
			await Promise.all([
				Font.loadAsync({
					...FontAwesome5.font,
					//Raleway_100Thin,
					//Raleway_200ExtraLight,
					//Raleway_300Light,
					//Raleway_400Regular,
					Raleway_500Medium,
					Raleway_600SemiBold,
					Raleway_700Bold,
					//Raleway_800ExtraBold,
					//Raleway_900Black,
					//Raleway_100Thin_Italic,
					//Raleway_200ExtraLight_Italic,
					//Raleway_300Light_Italic,
					//Raleway_400Regular_Italic,
					Raleway_500Medium_Italic,
					Raleway_600SemiBold_Italic,
					Raleway_700Bold_Italic,
					//Raleway_800ExtraBold_Italic,
					//Raleway_900Black_Italic,
				}),
			]);
			setLoading(false);
		}

		loadAssets();
	}, []);

	const onLayoutRootView = async () =>
		!isLoading && (await SplashScreen.hideAsync());

	return isLoading ? null : (
		<SafeAreaProvider>
			<RootSafeAreaProvider>
				<HeaderButtonsProvider>
					<Provider store={store}>
						<PersistGate persistor={persistor}>
							<MedicationProvider>
								<NewsProvider>
									<SmpcProvider>
										<RootNavigationContainer>
											<GestureHandlerRootView style={styles.container}>
												<NativeBottomSheetModalProvider>
													<View
														style={styles.container}
														onLayout={onLayoutRootView}
													>
														<StatusBar style="light" />
														<RootNavigator />
													</View>
												</NativeBottomSheetModalProvider>
											</GestureHandlerRootView>
										</RootNavigationContainer>
									</SmpcProvider>
								</NewsProvider>
							</MedicationProvider>
						</PersistGate>
					</Provider>
				</HeaderButtonsProvider>
			</RootSafeAreaProvider>
		</SafeAreaProvider>
	);
}

export default Sentry.wrap(App);

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#FFFFFF",
	},
});
