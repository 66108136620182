import React from "react";
import { StyleSheet, Text, TextInput, View } from "react-native";

export default function LoginForm(props) {
	const handleChange = React.useCallback(
		(field, value) => {
			props.onChange({
				...props.login,
				[field]: value,
			});
		},
		[props.onChange],
	);

	const onChangeText = React.useCallback(
		(text) => handleChange("code", text),
		[handleChange],
	);

	// TODO: [Android] Capitalizing login code does not work

	return (
		<View style={[styles.form, props.style]}>
			<Text style={styles.labelText}>Inlogcode</Text>
			<TextInput
				style={styles.textInput}
				autoCorrect={false}
				clearButtonMode="while-editing"
				enablesReturnKeyAutomatically
				onChangeText={onChangeText}
				onSubmitEditing={props.onSubmit}
				value={props.login.code}
			/>
		</View>
	);
}

LoginForm.validate = (login) => {
	return login && login.code;
};

const styles = StyleSheet.create({
	form: {
		padding: 20,
		paddingTop: 0,
	},

	labelText: {
		paddingTop: 40,
		paddingBottom: 10,
		fontFamily: "Raleway_700Bold",
		fontSize: 20,
	},

	textInput: {
		padding: 10,
		borderWidth: 2,
		borderColor: "#0099CC",
		borderRadius: 8,
		fontFamily: "Raleway_500Medium",
		fontSize: 20,
	},
});
