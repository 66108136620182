import React from "react";
import { AppState } from "react-native";

import useDatabaseQuery from "../hooks/useDatabaseQuery";

const visibleProperties = [
	"patent",
	"preservative",
	"active-ingredient",
	//"mode-of-action",
	"dosage",
	"packing-unit",
	"storage-advice",
];

const filterableProperties = [
	"class",
	"patent",
	"preservative",
	"active-ingredient",
	//"mode-of-action",
	"dosage",
	"packing-unit",
	"storage-advice",
];

const notExactFilteredProperties = ["active-ingredient"];

const propertyLabelOverrides = {
	"mode-of-action": "Werking",
	"packing-unit": "Verpakking",
	"storage-advice": "Bewaren",
};

const getOptionTitle = (properties, optionId) => {
	const property = properties.find((property) =>
		optionId.startsWith(`${property.id}/`),
	);
	const option = (property ? property.options : []).find(
		(option) => optionId === option.id,
	);
	return option ? option.title : undefined;
};

const formatActiveIngredients = (properties, options) => {
	return (options || [])
		.map((option) =>
			[getOptionTitle(properties, option["ingredient"]), option.quantity].join(
				" ",
			),
		)
		.join(" + ");
};

export const MedicationContext = React.createContext();

export function MedicationProvider(props) {
	const value = useDatabaseQuery({
		query: "page('medication')",
		select: {
			medication: {
				query: "page.children.listed",
				select: {
					uuid: true,
					oldIds: true,
					name: "page.title",
					class: true,
					patent: true,
					preservative: true,
					"active-ingredients": "page.activeIngredients.toStructure",
					"mode-of-action": "page.modeOfAction",
					dosage: true,
					"packing-unit": "page.packingUnit",
					"storage-advice": "page.storageAdvice",
					brand: true,
				},
			},
			properties: {
				query: "page.find('properties').children.listed",
				select: {
					id: true,
					title: true,
					options: {
						query: "page.children.listed",
						select: ["id", "title"],
					},
				},
			},
		},
	});

	const processedValue = React.useMemo(
		() => ({
			...value,
			result: {
				medication: (value.result ? value.result.medication : []).map(
					(item) => ({
						...item,
						oldIds: item.oldIds.split(",").filter((id) => !!id),
						class: getOptionTitle(value.result.properties, item.class),
						patent: getOptionTitle(value.result.properties, item.patent),
						preservative: getOptionTitle(
							value.result.properties,
							item.preservative,
						),
						"active-ingredient": formatActiveIngredients(
							value.result.properties,
							item["active-ingredients"],
						),
						"mode-of-action": getOptionTitle(
							value.result.properties,
							item["mode-of-action"],
						),
						dosage: getOptionTitle(value.result.properties, item.dosage),
						"packing-unit": getOptionTitle(
							value.result.properties,
							item["packing-unit"],
						),
						"storage-advice": getOptionTitle(
							value.result.properties,
							item["storage-advice"],
						),
						brand: getOptionTitle(value.result.properties, item.brand),
					}),
				),
				properties: (value.result ? value.result.properties : []).map(
					(item) => {
						const key = item.id.split("/").pop();
						return {
							...item,
							key,
							label: propertyLabelOverrides[key] || item.title,
							visible: visibleProperties.includes(key),
							filterable: filterableProperties.includes(key),
							filterExact: !notExactFilteredProperties.includes(key),
							filterValues: (item.options || []).map((option) => option.title),
						};
					},
				),
			},
		}),
		[value],
	);

	React.useEffect(() => {
		const subscription = AppState.addEventListener(
			"change",
			(appState) => appState === "active" && value.reload(),
		);

		return () => subscription.remove();
	}, [value.reload]);

	return (
		<MedicationContext.Provider value={processedValue}>
			{props.children}
		</MedicationContext.Provider>
	);
}

export function useMedication() {
	return React.useContext(MedicationContext);
}

const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

export function useFilteredMedicines(filters) {
	const medicines = useMedication().result.medication;
	const properties = useMedication().result.properties;

	return React.useMemo(() => {
		filters = Object.entries(filters).map(([property, values]) => {
			const filter = properties.find(({ key }) => key === property);

			return [
				property,
				values.map(
					(value) =>
						new RegExp(
							filter && filter.filterExact
								? "^" + escapeRegExp(value) + "$"
								: "(^|\\s|\\b)" + escapeRegExp(value) + "(\\b|\\s|$)",
							"iu",
						),
				),
			];
		});

		return medicines.filter((medicine) =>
			filters.every(([property, values]) =>
				values.some((value) => value.test(medicine[property])),
			),
		);
	}, [medicines, properties, filters]);
}

export function useSearchedMedicines(query, properties) {
	const medicines = useMedication().result.medication;

	return React.useMemo(() => {
		properties = properties || ["name", "activeSubstance", "brand"];

		const filters = query
			.split(" ")
			.filter((value) => value)
			.map((value) => new RegExp(escapeRegExp(value), "iu"));

		return medicines.filter((medicine) =>
			filters.every((value) =>
				properties.some((property) => value.test(medicine[property])),
			),
		);
	}, [medicines, query, properties]);
}

export function isFavorited(medicine, favorites) {
	return (
		favorites.includes(medicine.uuid) ||
		medicine.oldIds.some((id) => favorites.includes(id))
	);
}

export function useFavoritedMedicines(favorites) {
	const medicines = useMedication().result.medication;

	return React.useMemo(
		() => medicines.filter((medicine) => isFavorited(medicine, favorites)),
		[medicines, favorites],
	);
}
